import React, { useEffect } from "react"
import PreHeader from "../components/preHeader"
import HeaderDrak from "../components/headerDrak"
import Footer from "../components/footer"
import { Container, Row, Col } from "react-bootstrap"
import backBtn from "../images/arrow-grren-outline.svg"
import "../styles/main.scss"

// import for translate
import {
  AUTH_TOKEN_KEY,
  COLOR_GREEN,
  COLOR_RED,
  DOMAIN_API,
  TYPE_ACTU,
  TYPE_ADVANTAGE,
  TYPE_ALL,
  URL_GET_USER_FULL,
  URL_GETALL_ADVANTAGES,
  URL_GETALL_ADVICEES, URL_GETALL_FOLDERS, URL_GETALL_NEWS, URL_GETALL_VIDEOS, URL_GETALL,
  URL_IS_AUTH, translate, URL_GET_MAINPARTNERSUPER_DATA, URL_GETALL_PARTNER_SUPER_FILTERED, getUserLanguage, FR
} from "../utils"
import TagManager from "react-gtm-module"

const PrivacyTerms = () => {
  useEffect(() => {
    const userLanguage = getUserLanguage()
    if (userLanguage === FR) window.location.href = '/site-terms-fr'
  },[])

  return (
    <div>
      <PreHeader />
      <div className="privacyTerms">
        <HeaderDrak />
        <div className="pageContent">
          <Container>
            <Row className="justify-content-center align-items-lg-end">
              <Col lg="8" className="order-lg-2">
                <h1 className="h0-title yellow-privacy">
                <span className="d-block">
                Gebruikersvoorwaarden van de website</span>
                </h1>

                {/* paragraphe one start */}
                <h2 className="h2-title yellow-privacy">ARTIKEL 1 : WETTELIJKE VERMELDINGEN</h2>
                <p className="yellow-privacy">
                  De website <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com </a>
                  wordt beheerd door vzw Federatie van In Tempore Bv (hierna « In Tempore»), met hoofdzetel gevestigd te 306, Kersbeeklaan 1180 Brussel Kruispuntbank van ondernemingen onder het nummer BE 0477 292 458. Door de website te betreden en te gebruiken gaat u zonder enig voorbehoud akkoord met de huidige gebruiksvoorwaarden (hierna de « Gebruiksvoorwaarden ») en verbindt u zich ertoe om deze na te leven.
                </p>
                {/* paragraphe one end */}

                {/* paragraphe two start */}
                <h2 className="h2-title  yellow-privacy">ARTIKEL 2 : DOEL</h2>
                <p className="yellow-privacy">
                  De huidige « Algemene Gebruiksvoorwaarden » zorgen voor de juridische omkadering van de voorwaarden die verbonden zijn aan het gebruik van de diensten die aangeboden worden via de website van In Tempore https://<a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com </a>
                  , hierna genoemd « de Website », door de bezoeker, hierna genoemd « de Gebruiker ».
                </p><
                p className="yellow-privacy">
                De Algemene Gebruiksvoorwaarden moeten aanvaard worden door elke Gebruiker die toegang wenst te krijgen tot de Website. De toegang tot de Website door de Gebruiker houdt automatisch in dat deze de huidige Algemene Gebruiksvoorwaarden aanvaardt.
              </p>
                <p className="yellow-privacy">
                  Indien de Gebruiker de opgesomde Algemene Voorwaarden niet aanvaardt dan dient hij af te zien van de toegang tot de diensten die voorgesteld worden op deze Website.
                </p>
                <p className="yellow-privacy">
                  In Tempore behoudt zich het recht voor om unilateraal en op om het even welk moment de inhoud van de huidige Algemene Gebruiksvoorwaarden te wijzigen.
                </p>
                <p className="yellow-privacy">
                  Indien een van de voorwaarden die opgesomd worden in deze Algemene Gebruiksvoorwaarden niet van toepassing is op, of in tegenspraak is met, een dwingende wettelijke bepaling, dan heeft dit geen gevolgen voor de geldigheid of toepasselijkheid van alle andere bepalingen.
                </p>
                <p className="yellow-privacy">
                  De huidige Algemene Gebruiksvoorwaarden zijn specifieke voorwaarden die uitsluitend betrekking hebben op het gebruik van de Website. Zij worden aangevuld met de Algemene Voorwaarden evenals het Charter inzake het Privéleven van In Tempore, die integraal van toepassing zijn op de juridische relatie tussen In Tempore en de Websitegebruikers.
                </p>

                {/* paragraphe two end */}

                {/* paragraphe three start */}
                <h2 className="h2-title yellow-privacy">ARTIKEL 3 : DEFINITIES</h2>
                <p className="yellow-privacy">
                  Deze clausule heeft tot doel de essentiële termen van dit contract duidelijk te definiëren.
                  <p className="yellow-privacy"></p>
                  Gebruiker : deze term verwijst naar elke persoon die de Website of een van de diensten die daarop aangeboden worden gebruikt.
                </p><p className="yellow-privacy">
                Gebruikersgegevens: dit zijn de gegevens die de Gebruiker meedeelt via de Website.
              </p><p className="yellow-privacy">
                Lid : de Gebruiker wordt lid van zodra hij zich geïdentificeerd heeft op de Website.
              </p><p className="yellow-privacy">
                Gebruikersnaam en paswoord : dit zijn alle inlichtingen die nodig zijn om een Gebruiker op de Website te identificeren. De gebruikersnaam en het paswoord laten de Gebruiker toe om toegang te krijgen tot diensten die voorbehouden zijn voor de leden van de Website. Het paswoord is vertrouwelijk.
              </p>


                {/* paragraphe three ends */}





                {/* paragraphe four starts */}
                <h2 className="h2-title yellow-privacy">ARTIKEL 4 : TOEGANG</h2>
                <p className="yellow-privacy">
                  De Website geeft de Gebruiker gratis toegang tot diensten van de Website.
                </p><p className="yellow-privacy">
                De Website is gratis en overal toegankelijk voor de Gebruiker die over een internetaansluiting beschikt. De kosten om toegang te krijgen tot de diensten van de Website (informaticamateriaal, programma’s, internetaansluiting enz.) zijn ten laste van de Gebruiker.
              </p><p className="yellow-privacy">
                In Tempore spaart geen moeite om de Gebruiker in de mate van het mogelijke toegang te verlenen tot zijn Website.
              </p><p className="yellow-privacy">
                Die toegang kan evenwel onderbroken worden, meer bepaald ingeval van onderhoud en updates, pannes of om andere technische redenen.
              </p><p className="yellow-privacy">
                In Tempore weigert elke aansprakelijkheid ingeval van pannes, onderbrekingen of fouten op de Website, inclusief voor de gevolgen die daaruit kunnen voortvloeien voor de Gebruiker of derden.
              </p>

                {/* paragraphe four ends */}

                {/* paragraphe five starts */}
                <h2 className="h2-title yellow-privacy">ARTIKEL 5 : INTELLECTUELE EIGENDOM</h2>


                <p className="yellow-privacy">
                  De Website, inclusief de teksten, de structuur, de lay-out, de grafische elementen, de presentatie, de logo’s, het programma en alle andere elementen die eventueel voorkomen op de Website zijn beschermd door de intellectuele eigendomsrechten van In Tempore, zijn gebeurlijke leveranciers of partners. Die intellectuele eigendomsrechten omvatten onder andere – maar zijn niet beperkt tot – de auteursrechten, burenrechten, rechten verbonden aan gegevensbanken en merkenrechten.
                </p>

                <p className="yellow-privacy">
                  Andere namen van producten of bedrijven die op de Website vermeld zijn, kunnen merken van hun respectievelijke eigenaars zijn. Zonder voorafgaand schriftelijk akkoord van de rechthebbenden of In Tempore mag de Gebruiker deze in geen geval wijzigen, kopiëren, verdelen, meedelen, vertalen, verspreiden, vermenigvuldigen, publiceren, toekennen met een licentie of afstaan, noch de informatie, programma’s, producten of diensten die op deze sites bekomen worden verkopen. Het is ook verboden om afgeleide producten van de hierboven vermelde zaken te ontwerpen.
                </p>

                {/* paragraphe five ends */}


                {/* paragraphe six starts */}
                <h2 className="h2-title yellow-privacy">ARTIKEL 6 : AANSPRAKELIJKHEID EN OVERMACHT</h2>

                <p className="yellow-privacy">
                  De informatiebronnen, producten en diensten die voorgesteld worden op de Website hebben een betrouwbare reputatie. In Tempore biedt evenwel geen enkele waarborg betreffende de juistheid of actualiteitswaarde van de informatie die voorkomt op de Website. Alle informatie wordt uitsluitend om informatieve doeleinden meegedeeld via de Website. De Gebruiker is bijgevolg als enige volledig verantwoordelijk voor het gebruik van de informatie en de elementen die voorkomen op deze Website.
                </p><p className="yellow-privacy">
                De Gebruiker zorgt voor de geheimhouding van zijn paswoord. Elke verspreiding van het paswoord op enigerlei wijze is verboden.
              </p><p className="yellow-privacy">
                De Gebruiker aanvaardt de risico’s die verband houden met het gebruik van zijn gebruikersnaam en paswoord. In Tempore aanvaardt geen enkele verantwoordelijkheid op dit vlak.
              </p><p className="yellow-privacy">
                Elk gebruik van de diensten door de Gebruiker dat rechtstreeks of onrechtstreeks schade veroorzaakt, leidt tot een schadevergoeding ten gunste van In Tempore.
              </p><p className="yellow-privacy">
                De Website / In Tempore biedt geen optimale waarborg van de veiligheid en vertrouwelijkheid van de meegedeelde gegevens. In Tempore verbindt zich er evenwel toe om alle redelijke middelen in te schakelen om zo goed mogelijk de veiligheid en vertrouwelijkheid van de gegevens te waarborgen.
              </p>

                <p className="yellow-privacy">
                  De aansprakelijkheid van de Website kan niet ingeroepen worden ingeval van overmacht of een onvoorziene en onoverkomelijke gebeurtenis veroorzaakt door derden. Meer bepaald kan In Tempore niet verantwoordelijk gesteld worden voor het niet uitvoeren van contracten die afgesloten werden dankzij de toegang tot zijn Website omdat een dienst niet beschikbaar was of ingeval van overmacht.
                </p><p className="yellow-privacy">
                De Gebruiker verbindt zich er dus toe om geen schadevergoeding te vorderen naar aanleiding van een onderbreking, opschorting of wijziging van de huidige gebruiksvoorwaarden.
              </p>


                {/* paragraphe six ends */}




                {/* paragraphe seven starts */}
                <h2 className="h2-title yellow-privacy">ARTIKEL 7 : HYPERLINKS</h2>
                <p className="yellow-privacy">
                  De websites waarnaar de gebruikers van de Website doorverwezen kunnen worden via hyperlinks vallen volledig binnen de verantwoordelijkheid van de eigenaars van die websites.
                </p><p className="yellow-privacy">
                IIn Tempore verwerpt elke aansprakelijkheid ten opzichte van het materiaal en de gegevens van gelijk welke aard die voorkomen op of verspreid worden via die websites, inclusief de manier waarop deze de persoonsgegevens verwerken. In Tempore is inderdaad slechts verantwoordelijk voor de verwerking van de persoonsgegevens op zijn eigen Website, zoals vermeld in het Charter inzake het privéleven.
              </p>


                {/* paragraphe seven ends */}

                {/* paragraphe eight starts */}
                <h2 className="h2-title yellow-privacy">ARTIKEL 8 : PERSOONSGEGEVENS</h2>
                <p className="yellow-privacy">
                  De inlichtingen die opgevraagd worden bij de inschrijving op de Website zijn noodzakelijk en verplicht in het kader van bepaalde diensten die aangeboden worden aan de Gebruiker. Meer bepaald kan het e-mailadres door de Website gebruikt worden om vragen van de Gebruiker te beantwoorden en om met hem te communiceren.
                </p><p className="yellow-privacy">
                De Website garandeert de Gebruiker dat diens persoonsgegevens verzameld en verwerkt worden met respect voor het privéleven, en dit overeenkomstig de Europese Verordening (EU) 2016/679 van 27 april 2016 die op 25 mei 2018 van kracht werd – de Algemene verordening gegevensbescherming (AVG) – met gedeeltelijke intrekking van de Belgische wet van 8 december 1992 met betrekking tot het privéleven en de verwerking van persoonsgegevens die tot dan toe van kracht was, en de (toekomstige) Belgische wetgeving inzake de toepassing van de hierboven vermelde regelgeving.
              </p>
                <p className="yellow-privacy">
                  Voor alle informatie met betrekking tot de verwerking van de persoonsgegevens van de Gebruiker verwijzen wij deze laatste door naar het Charter inzake het privéleven.      </p>

                {/* paragraphe eight ends */}



                {/* paragraphe nine starts */}
                <h2 className="h2-title yellow-privacy">ARTIKEL 9 : TOEPASSELIJK RECHT EN BEVOEGDHEID</h2>

                <p className="yellow-privacy">
                  De huidige Algemene Gebruiksvoorwaarden zijn volledig en uitsluitend onderworpen aan het Belgisch recht.
                </p>
                <p className="yellow-privacy">
                  Ingeval van betwisting inzake de uitvoering of de interpretatie van de huidige Algemene Voorwaarden zijn alleen de rechtbanken van de regio waarin de hoofdzetel van In Tempore gevestigd is bevoegd.
                </p>


                {/* paragraphe nine ends */}


              </Col>
              {/*<Col lg="2" className="text-center mt-5">
                <a href={'/feed'} className="btn BackBtn">
                  <img
                    className="img-fluid"
                    src={backBtn}
                    alt="Enter Business"
                  />
                  <span>Back</span>
                </a>
              </Col>*/}
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default PrivacyTerms



